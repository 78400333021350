'use client'

import React, { createContext } from 'react'
import { defaultLanguage } from '@/lib/languages'

const App = createContext()

const AppProvider = ({ children, language }) => {
  const currentLanguage = language || defaultLanguage

  return <App.Provider value={{ currentLanguage }}>{children}</App.Provider>
}

const useAppContext = () => {
  const context = React.useContext(App)
  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider')
  }
  return context
}

export { AppProvider, useAppContext }
